<template>
  <div class="details-page-overlay" :class="{ open: overlayOpen , closed: !overlayOpen  }">
    <div class="details-page">
      <div class="outer-layer" @click="closePageOverlay" ref="closeButtonRef"></div>
      <div class="ui active inverted dimmer page-loader" v-if="loading">
        <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
      </div>
      <div class="details-page-content" @keydown.esc="closePageOverlay">
        <span id="overlay-close" tabindex="1" class="material-icons close" @click="closePageOverlay"> close </span>
        <div class="restrict-width">
          <slot v-if="!loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  components: { Spinner },
  props: {
    overlayOpen: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closePageOverlay() {
      this.$emit('close-overlay')
    }
  },
  watch: {
    overlayOpen(open) {
      if (open && this.$refs.closeButtonRef) {
        setTimeout(() => {
          this.$refs.closeButtonRef.scrollIntoView()
        }, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables.scss';

.details-page-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 100;
  &.closed {
    display: none;
  }
  &.open {
    width: 100%;

    @media only screen and (max-width: $breakpoint-xs-max) {
      background-color: transparent;
    }

    .outer-layer {
      width: 20%;
      background-color: var(--color-primary-500);
      opacity: 0.9;

      @media only screen and (max-width: $breakpoint-xs-max) {
        width: 0;
      }
    }

    .details-page {
      width: 100%;
      z-index: 99;

      .details-page-content {
        width: 80%;
        height: 100vh;
        background-color: $color-white;
        padding: 70px 40px 40px 40px;
        opacity: 1;
        overflow-y: scroll;

        &:focus {
          outline: none;
          font-weight: bold;
        }

        @media only screen and (max-width: $breakpoint-xs-max) {
          padding: 40px 20px;
          width: 100%;
        }

        .close {
          display: block;
          position: absolute;
          top: 22px;
          left: 35px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1);
            color: var(--color-primary-500);
          }
          @media only screen and (max-width: $breakpoint-xs-max) {
            padding: 0;
            top: 10px;
            left: 1px;
          }
        }

        .restrict-width {
          max-width: 1000px;
        }
      }
    }
  }

  .details-page {
    transition: all 0.3s;
    position: absolute;
    width: 0;
    right: 0;
    box-shadow: $lift-box-shadow;
    top: 0;
    display: flex;

    @media only screen and (min-width: $breakpoint-xs-max) {
      /deep/.loading-bar {
        top: calc(50% - 40px);
        left: calc(60% - 40px);
      }
    }

    .details-page-content {
      position: relative;
      transition: all 0.3s;
      padding: 0;
      opacity: 0;

      .close {
        display: none;
      }
    }
  }
}
</style>
